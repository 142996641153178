<template>
    <div data-app="true" class="datepicker-box">
        <v-dialog
                ref="dialog"
                v-model="dateModal"
                :return-value.sync="date"
                persistent
                lazy
                full-width
                width="290px"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                        v-model="date"
                        append-icon="event"
                        hide-details
                        readonly
                        v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker v-model="date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text class="element-btn bg-gray" @click="dateModal = false">취소</v-btn>
                <v-btn text class="element-btn bg-red" @click="timeModal = true">확인</v-btn>
            </v-date-picker>
        </v-dialog>

        <v-dialog
                ref="dialog2"
                v-model="timeModal"
                :return-value.sync="time"
                persistent
                lazy
                full-width
                width="290px"
        >
            <v-time-picker
                    v-if="timeModal"
                    v-model="time"
                    use-seconds
                    scrollable
                    full-width
            >
                <v-spacer></v-spacer>
                <v-btn text color="primary" class="element-btn bg-gray" @click="timeModal = false">취소</v-btn>
                <v-btn text color="primary" class="element-btn bg-red" @click="set()">확인</v-btn>
            </v-time-picker>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "DateTimePicker",
        props: ['label'],
        data: () => ({
            date: "",
            dateModal: false,
            time: "",
            timeModal: false,
        }),
        methods: {
            set() {
                this.date = this.date +" "+ this.time;
                this.$refs.dialog.save(this.date);
                this.$refs.dialog2.save(this.time);
            }
        }
    }
</script>